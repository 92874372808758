import React, { Component } from 'react';
import '../css/custom.css';
import { connect } from 'react-redux';
import { Redirect, Link } from "react-router-dom";
import axios from "axios";
import { saveUrl, updateGame, inProgress, noProgress, loadUsers, newGame } from "../actions"
import Spinner from "./additional/Spinner";
import server from '../server';
import trimString from '../helpers/trim-string';
import howMuchTrim from '../helpers/trim-amount';
import addMargin from '../helpers/add-margin';
import breakpoint from '../helpers/breakpoints';
import { CgArrowRightR, CgArrowLeftR } from "react-icons/cg";

class NewGame extends Component {
  constructor(props) {
    super(props);
    this.users = this.props.users //this.props.users.filter(el => this.now() - el.lastTimePaid < 86400000); //For payment functionality
    this.state = {
      initial: this.users,
      users: this.users,
      pages: Math.ceil(this.users.length / this.props.config.usersPerPage),
      show: this.users.slice(0, this.props.config.usersPerPage),
      currentPage: 1,
      players: [],
      rating: true,
      search: ''
    };
  }

  componentDidMount() {
    this.props.newGame();
    this.props.inProgress();
    this.props.saveUrl(this.props.location.pathname);
    const content = this;
    axios.get(server.address + '/mafia-users/')
      .then(res => {
        const data = res.data.sort((a, b) => b.rating - a.rating);
        data.forEach((el, i) => {
          el.no = i + 1;
        });
        content.setState({
          users: data,
          pages: Math.ceil(data.length / this.props.config.usersPerPage),
          show: data.slice(0, this.props.config.usersPerPage)
        }, () => content.props.loadUsers(data));
      })
      .catch(error => console.log(error))
      .finally(() => {
        content.props.noProgress()
      });
  }

  renderPagination() {
    return this.state.pages > 1 ?
      <nav aria-label="Page navigation">
        <ul className="pagination content-middle mb-2">
          {this.pagePagination()}
        </ul>
      </nav>
      :
      <div className='pagination-placeholder'></div>
  }

  renderStartButton() {
    return <button className='btn btn-sm btn-warning mb-3'
      disabled={(this.state.rating === true && this.state.players.length !== 10) || (this.state.rating === false && !(this.state.players.length >= 9 && this.state.players.length <= 10))}
      onClick={() => this.startGame()}>
      {this.props.config.buttons.start}
    </button>
  }

  searchUsers(input) {
    const content = this;
    let list = this.props.users.filter(el => this.state.players.find(player => player._id === el._id) === undefined);
    let array = this.state.search.split(' ');
    let compareArray = list;

    array.forEach(function (word) {
      let res = list.filter(el =>
        el.firstName.toLowerCase().includes(word.toLowerCase()) ||
        el.lastName.toLowerCase().includes(word.toLowerCase()) ||
        el.nickName.toLowerCase().includes(word.toLowerCase()));
      const intersection = compareArray.filter(element => res.includes(element));
      compareArray = intersection;

      content.setState({
        users: intersection,
        pages: Math.ceil(intersection.length / content.props.config.usersPerPage),
        show: intersection.slice(0, content.props.config.usersPerPage),
      });
    });
  }

  getTime() {
    let today = new Date();
    let year = today.getFullYear();
    let month = today.getMonth();
    let day = today.getDate();
    let hours = today.getHours();
    let minutes = today.getMinutes();
    return `${year}/${month + 1}/${day}_${hours}:${minutes < 10 ? 0 + minutes.toString() : minutes}`;
  }

  startGame() {
    const gamers = this.state.players;
    const numberOfCitizen = this.state.rating ? 6 : (gamers.length === 10 ? 6 : 5);
    if (this.state.rating === false && gamers.length < 10) {
      gamers.push({
        _id: 'dummy',
        firstName: 'dummy',
        lastName: 'dummy',
        email: 'dummy',
        nickName: 'dummy',
        sex: 'male',
        role: 'Citizen',
        alive: true,
        fouls: '',
        muted: false
      })
    }
    const game = {
      startDate: this.getTime(),
      rating: this.state.rating,
      endDate: '',
      players: gamers,
      roles: { Mafia: 2, Don: 1, Sheriff: 1, Citizen: numberOfCitizen },
      whoWon: 0,
      phase: '/game/initial',
      cycle: 0,
      killedLastNight: false,
      deadPlayers: [],
      disqualified: [],
      firstWord: 1,
      vote: [],
      voting: [],
      comments: [],
      activePlayer: 0,
      talked: [],
      currentComment: ''
    };
    this.postData(game);
    this.setState({ startGame: true }, () => this.props.updateGame(game));
  }

  postData(game) {
    this.props.inProgress();
    const content = this;
    axios.post(server.address + '/mafia-games/', game)
      .then((res) => {
        this.props.updateGame(res.data.game)
      })
      .then(() => {
        content.setState({ startGame: true })
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        this.props.noProgress();
      });
  }

  pagePagination() {
    if (this.state.pages > 9) { //if more than 9 pages then trim

      if (this.state.currentPage <= 5) {  //if the current page is below or equal to 5 then just show:
        let bar = [];

        for (let i = 1; i <= 7; i++) { //first 7 pages
          bar.push(<li key={i} className="page-item hover-item">
            <span className={`page-link ${this.state.currentPage === i ? 'current-link' : ''}`}
              onClick={() => this.handleClick(i)}>{i}
            </span></li>);
        }

        //ellipsis
        bar.push(<li key={'elipsis-1'} className="page-item">
          <span className="page-elipsis">...</span></li>);

        // last page
        bar.push(<li key={this.state.pages} className="page-item hover-item">
          <span className="page-link"
            onClick={() => this.handleClick(this.state.pages)}>{this.state.pages}</span></li>);
        return bar;
      } else if (this.state.currentPage > 5 && this.state.currentPage < this.state.pages - 4) {
        let bar = [];

        //first page
        bar.push(<li key="1" className="page-item hover-item">
          <span className="page-link"
            onClick={() => this.handleClick(1)}>1</span></li>);

        //ellipsis
        bar.push(<li key={'elipsis-1'} className="page-item">
          <span className="page-elipsis">...</span></li>);

        for (let i = this.state.currentPage - 2; i <= this.state.currentPage + 2; i++) {
          bar.push(<li key={i} className="page-item hover-item">
            <span className={`page-link ${this.state.currentPage === i ? 'current-link' : ''}`}
              onClick={() => this.handleClick(i)}>{i}</span></li>);
        }

        //ellipsis
        bar.push(<li key={'elipsis-2'} className="page-item">
          <span className="page-elipsis">...</span></li>);

        // last page
        bar.push(<li key={this.state.pages} className="page-item hover-item">
          <span className="page-link"
            onClick={() => this.handleClick(this.state.pages)}>{this.state.pages}</span></li>);

        return bar;
      } else if (this.state.currentPage >= this.state.pages - 4) { //else just show six pages around the current one
        let bar = [];

        //first page
        bar.push(<li key="1" className="page-item hover-item">
          <span className="page-link"
            onClick={() => this.handleClick(1)}>1</span></li>);

        //ellipsis
        bar.push(<li key={'elipsis-1'} className="page-item">
          <span className="page-elipsis">...</span></li>);

        for (let i = this.state.pages - 6; i <= this.state.pages; i++) {
          bar.push(<li key={i} className="page-item hover-item">
            <span className={`page-link ${this.state.currentPage === i ? 'current-link' : ''}`}
              onClick={() => this.handleClick(i)}>{i}</span></li>);
        }

        return bar;
      }
    } else { //if 9 pages or less then just show the list of all pages
      let bar = [];

      for (let i = 1; i <= this.state.pages; i++) {
        bar.push(<li key={i} className="page-item hover-item">
          <span className={`page-link ${this.state.currentPage === i ? 'current-link' : ''}`}
            onClick={() => this.handleClick(i)}>{i}</span></li>);
      }

      return bar;
    }
  }

  handleClick(page) {
    if (this.state.currentPage > 1 && page === 'prev') {
      this.setState({ currentPage: this.state.currentPage - 1 }, () => this.updateShow())
    } else if (this.state.currentPage < this.state.pages && page === 'next') {
      this.setState({ currentPage: this.state.currentPage + 1 }, () => this.updateShow())
    } else if (Number.isInteger(page)) {
      this.setState({ currentPage: page }, () => this.updateShow())
    }
  }

  updateShow() {
    let whatToShow = this.state.users.slice(
      (this.state.currentPage - 1) * this.props.config.usersPerPage,
      this.props.config.usersPerPage * this.state.currentPage
    );
    this.setState({ show: whatToShow });
  }

  updatePages() {
    let pages = Math.ceil(this.state.users.length / this.props.config.usersPerPage);
    let currentPage = this.state.currentPage;
    if (currentPage > pages && currentPage > 1) {
      currentPage -= 1;
    }
    this.setState({ pages: pages, currentPage: currentPage }, () => this.updateShow());
  }

  now() {
    let date = new Date();
    return date.getTime();
  }

  addPlayerToGame(player) {
    const game = this.state.players;
    const users = this.state.users;
    const gamer = player;
    gamer.role = '';
    gamer.alive = true;
    gamer.fouls = '';
    gamer.muted = false;
    game.push(gamer);
    const newUsers = users.filter(el => el !== player);
    this.setState({ players: game, users: newUsers, search: '' }, () => {
      this.searchUsers();
      this.updatePages();
    });
  }

  removePlayer(player) {
    const game = this.state.players.filter(el => el._id !== player._id);
    const users = this.state.users;
    users.push(player);
    this.setState({ players: game, users: users.sort((a, b) => b.rating - a.rating) }, () => this.updatePages());
  }

  pressEnter(enter) {
    if (enter.key === 'Enter') {
      this.setState({ search: '' }, () => this.addPlayerToGame(this.state.show[0]))
    }
  }

  render() {
    if (!this.props.currentUser.moderator && !this.props.currentUser.admin) {
      return <Redirect to='/public-game' />
    } else {
      return this.props.progress
        ?
        <Spinner margin={true} />
        :
        this.state.startGame === true
          ?
          (<Redirect to='/game/initial' />)
          :
          (<div id='new-game' className="page-wrap">

            <table width="100%" className='mt-2 mb-2'>
              <tbody>
                <tr>
                  <td width="50%" className="text-center">
                    <input type="checkbox"
                      name="game"
                      value="rating"
                      checked={this.state.rating}
                      onChange={() => this.setState({ rating: !this.state.rating })} />
                    <span className='ml-2 mr-3'>{this.props.config.labels.ratingGame}</span>
                  </td>
                  <td width="50%" className="text-center">
                    <Link to={'/games'}>
                      <button className='btn btn-link' id='btn-continue'>{this.props.config.buttons.continue}</button>
                    </Link>
                  </td>
                </tr>
              </tbody>
            </table>

            <div>
              <input className="form-control form-control-sm"
                type="text"
                id="search"
                maxLength={100}
                value={this.state.search}
                placeholder={this.props.config.labels.search}
                onChange={(e) => this.setState({ search: e.target.value }, () => this.searchUsers())}
                onKeyPress={(e) => this.pressEnter(e)}
              />
            </div>

            <table className='table table-sm table-borderless mb-0'>
              <thead className='thead-light'>
                <tr className='players-table-row'>
                  <th width='50%'>{this.props.config.labels.players}</th>
                  <th width='50%'>{this.props.config.labels.game}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {this.state.show.length > 0 ?
                      <div>

                        <table className={addMargin(this.state.show.length)}>
                          <tbody>
                            {this.state.show.map((el) =>
                              <tr key={el.email} className='players-row players-table-row'>
                                <td width="90%" className="content-left">{trimString(el.nickName, howMuchTrim())}</td>
                                <td width="10%">
                                  <CgArrowRightR
                                    alt='add'
                                    className={`${this.state.players.length >= 10 ? 'disabled-icon' : 'hover-item'} button-icon`}
                                    onClick={() => this.state.players.length < 10 && this.addPlayerToGame(el)}
                                  />
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                      :
                      <div id='empty-list' className='empty-list'>
                        <table className={addMargin(this.state.show.length)}>
                          <tbody>
                            <tr>
                              <td colSpan='2'>
                                {this.props.config.messages.noSearchRes}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    }
                    {breakpoint() !== "S" && breakpoint() !== 'XS' ?
                      <div>
                        {this.renderPagination()}
                      </div>
                      :
                      null
                    }
                  </td>
                  <td>
                    <table className={addMargin(this.state.players.length)}>
                      <tbody>
                        {this.state.players.map((el, i) =>
                          <tr key={el.email} className='players-row players-table-row'>
                            <td width="10%">
                              <CgArrowLeftR
                                alt='del'
                                className='hover-item button-icon'
                                onClick={() => this.removePlayer(el)}
                              />
                            </td>
                            <td width="90%" className="content-right">{trimString(el.nickName, howMuchTrim())}</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    {breakpoint() !== "S" && breakpoint() !== 'XS' ?
                      <div>
                        {this.renderStartButton()}
                      </div>
                      :
                      null
                    }
                  </td>
                </tr>
              </tbody>
            </table>

            {breakpoint() === "S" || breakpoint() === 'XS' ?
              <div>
                {this.renderPagination()}
                {this.renderStartButton()}
              </div>
              :
              null
            }
          </div>
          );
    }
  }
}

const mapStateToProps = state => ({
  users: state.users,
  config: state.config,
  progress: state.progress,
  currentUser: state.currentUser
});

const mapDispatchToProps = dispatch => ({
  updateGame: args => dispatch(updateGame(args)),
  saveUrl: args => dispatch(saveUrl(args)),
  inProgress: () => dispatch(inProgress()),
  noProgress: () => dispatch(noProgress()),
  newGame: () => dispatch(newGame()),
  loadUsers: args => dispatch(loadUsers(args)),
})

export default connect(mapStateToProps, mapDispatchToProps)(NewGame);